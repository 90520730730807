
@media only screen and (max-width: 375px) {
    .MuiFormControl-root {
        margin: 0 !important;
        margin-top: 12px !important;
    }

    #demo-simple-select-helper {
        padding: 12px 14px !important;
        font-family: 'DM Sans Regular';
        font-size: .8rem;
        font-weight: 500;
    }

    #demo-simple-select-helper-label :active {
        font-family: 'DM Sans Regular';
        font-size: .8rem !important;
        transform: translate(11px, -8px) scale(0.7) !important;
    }
}
.see-also-partners {
    background-color: rgba(229, 229, 229, .2);
}

.see-also-container {
    display: flex;
    align-self: center;
    flex-direction: column;

    span {
        color: #f66e1c;
        font-family: 'DM Sans Medium';
        font-weight: 100;
        text-transform: uppercase;
    }
}

.partners-link {
    margin-left: 8px;
    margin-top: 8px;
}

.feedback {
    background-color: #151D64;
    display: flex;
    flex-direction: column;

    .congrats {
        font-size: 1.4rem !important;
    }

    span {
        margin: 24px;
        color: #fff !important;
    }
}

.click-to-action {
    display: flex;
    flex-direction: row;
}

.see-also-partners {
    .partners-row {
        display: flex;
        flex-direction: row;
        margin-top: 12px;
        flex-wrap: wrap;
        .partner-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .img-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid #C4C4C4;
            border-radius: 4px;
            padding: 8px;
            background-color: #fff;
            max-height: 60px;
        
            img {
                object-fit: contain;
                
            }
        }

        svg {
            width: 28px;
            height: 28px
        }
    }   
}

@media only screen and (max-width: 768px) {
    .affinities--container {
        .see-also-partners {
            padding: 18px 12px 12px;
            background-color: rgba($color: #E5E5E5, $alpha: .2);
            span {
                color: #f66e1c;
                font-weight: bold;
                font-size: .75rem;
            }
        }

        span {
            font-size: .75rem;
            color: #969596;
            flex: 1;
        }

        .partners-row {
            svg {
                width: 28px;
                height: 28px
            }
        }
    }    

    .img-container {
        img {
            width: 120px;
            height: 48px;
        }
    }
}

.affinities--container {
    display: flex;
    flex-direction: column;
}

.affinities--header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 18px;

    span {
        font-family: 'DM Sans Regular';
        font-weight: 600;
        font-size: 1rem;
    }
}

.affinities--list--container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;

    .affinity--card {
        background-color: white;
        border: 1px solid #E5E5E5;
        box-shadow: 0px 4px 4px #E7E7E7;
        border-radius: 12px;
    }
}

@media only screen and (min-width: 2000px) {
    .affinities--list--container {
        width: 55%;
        justify-content: normal !important;
        align-self: center;
    }

    .affinities--container {
        width: 100%;
    }

    .affinity--level-container {
        display: flex;
        flex-direction: column;
    }

    .affinity--card {
        width: 320px !important;
        margin: 12px;

        .politician--info-container {
            padding: 12px !important;

            .politician-party {
                margin-top: 12px;
                color: #969596;
            }
        }

        img {
            width: 124px !important;
            height: 124px !important;
            border-radius: 62px !important;
            border: 4px solid #f66e1c !important;
        }
    }

    .affinities--header {
        padding: 48px 48px 12px 48px !important;

        span {
            font-size: 1.4rem !important;
        }
    }

    .feedback {
        padding: 18px 248px;

        .congrats {
            font-size: 1.4rem !important;
        }

        .click-to-action {
            img {
                width: 8vw !important;
            }
        }
    }
}

// @media only screen and (max-width: 425px) {
.feedback {
    background-color: #151D64;
    display: flex;
    flex-direction: column;

    span:first-child {
        margin: 24px 24px 12px 24px;
    }

    span {
        font-family: 'DM Sans Regular';
        margin: 0 24px;
    }

    .click-to-action {  
        a {
            margin: 24px;
        }
        img {
            width: 130px;
        }
    }
}

.affinities--header {
    padding: 18px 18px 18px 6px;
    span {
        color: #151D64;
        font-size: .9rem;
        margin-left: 8px;
    }
}

.affinities--container {
    font-weight: .6rem;
    font-weight: 700;
}

.affinity--card {
    display: flex;
    flex-direction: row;
    width: 167px;
    padding: 0 4px;
    margin: 4px;

    .politician--info-container {
        padding: 8px 0 0 8px;
        max-width: 90%;
        word-break: break-word;
        display: flex;
        flex-direction: column;

        .politician-name {
            font-size: .9rem;
            font-weight: 700;
            font-family: 'DM Sans Medium';
            color: #010D77;
        }
    }

    .affinity--level-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px;

        .affinity--level {
            margin-top: 4px;
            font-weight: 700;
            font-family: 'DM Sans Medium';
            color: #f66e1c;
        }
    }

    img {
        width: 48px;
        height: 48px;
        aspect-ratio: 16/9;
        object-fit: contain;
        border-radius: 39px;
        border: 2px solid #f66e1c;
    }
}
// }

@media only screen and (max-width: 320px) {
    .feedback { 
        span:first-child {
            margin: 12px;
        }

        span {
            color: #fff !important;
            font-size: .8rem !important;
            font-family: 'DM Sans Regular';
            margin: 0 12px;
        }

        a {
            margin: 12px !important;
        }
    }

    .affinities--header {
        padding: 18px 0 4px 0 !important;
        span {
            font-size: .8rem;
        }
        span:first-child {
            font-size: .8rem;
        }
    }

    .affinity--card {
        width: 135px;
        margin-top: 8px;

        .politician--info-container {
            padding: 8px 0 0 8px;
            max-width: 90%;
            word-break: break-word;
            display: flex;
            flex-direction: column;

            .politician-party {
                font-size: .6rem;
                font-family: 'DM Sans Medium';
                font-weight: 700;
                margin-top: 4px;
            }
        }

        img {
            width: 38px;
            height: 38px;
            aspect-ratio: 16/9;
            object-fit: contain;
            border-radius: 19px;
            border: 1px solid #f66e1c;
        }
    }
}

@media screen and (min-width: 376px) {
    .affinities--list--container {
        justify-content: space-evenly;

        .affinity--card {
            width: 180px;
            padding: 0;
            margin-bottom: 12px;
        }
    }
    
}

@media only screen and (min-width: 1024px) {
    .see-also-container {
        padding: 12px 0 24px 0;
        margin-left: 10%;
    }

    .partner-container {
        svg {
            width: 28px;
        }
    }
    .affinities--header {
        padding-left: 10%;
        justify-content: inherit;
    }

    .affinities--list--container {
        width: 80%;
        align-self: center;
        justify-content: initial;

        .affinity--card {
            width: 24vw;

            img {
                width: 86px;
                height: 86px;
                border: 2px solid #f66e1c;
                border-radius: 42px;
            }

            .politician-party {
                margin-top: 12px;
                color: #969596;
            }
        }
    }

    .img-container {
        img {
            width: 7vw;
            height: 7vw;
        }

        margin-left: 12px;
    }
}
.card--header {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .card--header-left-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 25px;

        .card--theme-origin {
            color: #f66e1c;
        }
        
        .card--theme-origin-line {
            height: 2px;
            width: 100%;
            background-color: #f66e1c;
            border-radius: 8px;
            opacity: .75;
        }
    
        .card--category {
            text-transform: uppercase;
            font-family: 'DM Sans Medium';
            color: rgba(150, 149, 150, 0.6);
        }

        .card--bullet-highlight {
            color: #f66e1c;
        }
    }

    .card--header-right-content {
        min-height: 25px;
        span {
            color: #061D48;
        }
    }

    span {
        font-weight: 700;
        font-size: .8rem;
    }

}

@media only screen and (max-width: 375px) {
    .card--header {
        .card--header-left-content {
            .card--theme-origin-line {
                margin-bottom: 3px;
            }
        }
        span {
            font-size: .65rem
        }
    }

    .card--title-container {
        width: 90%;

        span {
            line-height: 1rem;
        }
        .card--title {
            margin-bottom: 4px;
        }
    }
}
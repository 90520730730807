$page-padding: 24px;
$color-highlight: #f66e1c;


.MuiModal-root {
    display: flex;
    align-items: 'center';
    justify-content: center;
}

.see--result {
    background-color: $color-highlight;
    color: #fff;
    font-family: 'DM Sans Medium';
    font-size: .9rem;
    border: 1px solid #e7e7e7;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 6px;
    box-shadow: 0px 2px 2px #e7e7e7;
    width: 70%;
    height: 24px;
}

.see--result.restart {
    background-color: green;
    width: 30%;
}

.modal--container {
    background-color: #fff;
    display: flex;
    flex-direction: column; 
    border-radius: 6px;
    padding: 24px 24px;
    margin: 60% 18px;
    height: fit-content;

    .modal--text {
        font-family: 'DM Sans Medium';
        font-size: .85rem;
        color: #969596;
    }
}

.result--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .MuiPopover-root {
        top: 0 !important;
    }
}

@media only screen and (min-width: 769px) {
    .see--result {
        height: 48px;
    }

    .see--result:first-child {
        margin-right: 16px;
    }

    .modal--container {
        margin: 0;
        width: 35%;
    }

    .MuiPopover-root {
        top: 0 !important;
    }

    .MuiModal-root {
        bottom: 50% !important;
    }

    .result--container {
        margin-top: 24px;
    }

    .modal--text {
        margin-bottom: 12px;
    }

    .modal--state-label {
        color: #969596;
        font-size: .8rem;
    }

    .redirect--text {
        margin-top: 12px;
    }
}

@media only screen and (max-width: 768px) {
    .footer {
        display: none;    
    }

    .vote--error {
        margin-top: 12px;
        font-size: .7rem;
        font-style: italic;
        font-family: 'DM Sans Medium';
        text-transform: uppercase;
        color: red;
    }
    .see--result {
        height: 38px;
        font-size: 0.8rem;
        padding: 0;
        box-shadow: none;
    }

    .see--result:first-child {
        margin-right: 12px;
    }

    .modal--container {
        padding: 8px;
        margin: 0 24px;

        .modal--state-label {
            font-size: .8rem;
            margin-top: 14px;
            color: #969596;
            font-weight: 700;
            font-family: 'DM Sans Medium';
        }

        .MuiFormControl-root {
            margin: 12px 0 !important;
        }

        .modal--text {
            font-size: .8rem;
            color: #969596;
        }

        .MuiInputLabel-root {
            font-size: .7rem;
            font-family: 'DM Sans Regular';
            font-weight: 700;
        }

        #modal-select {
            max-height: 24px;
            padding: 6px;
        }

        .MuiSvgIcon-root {
            font-size: 16px;
        }
    }
}

@media only screen and (min-width: 769px) {
    .position--container {
        margin-top: 8px;
    }
}
.position--container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .position--text {
        text-transform: uppercase;
        margin-top: 8px;
        font-family: 'DM Sans Medium';
    }

    .inFavor {
        color: rgba(0, 197, 49);
    }

    .against {
        color: rgba(233, 0, 0);
    }
}

.card--root {
    background-color: white;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 4px #E7E7E7;
    border-radius: 12px;
    margin: 12px 0;

    .card--container {
        padding: 4px 12px;
    }

    .card--title-container {
        margin: 8px 0;
        display: flex;
        flex-direction: column;

        .card--title {
            font-weight: 700;
            color: #f66e1c;
        }

        .card--subtitle {
            color: #969596;
            font-weight: 700;
        }

        .card--about {
            font-weight: 700;
            color: #969596;
        }

        .card--description {
            font-family: 'DM Sans Medium';
        }
    }
}

@media screen and (max-width: 768px) {
    .card--title {
        margin-top: 12px;
    }
    .card--subtitle {
        font-size: .75rem;
    }
    
    .card--about {
        margin-top: 12px;
    }

    .card--description {
        font-size: .75rem;
        margin-top: 12px;
        margin-bottom: 8px;
    }
}

@media screen and (min-width: 769px) {
    .card--list {
        max-width: 50%;
    }
    
    .card--title {
        margin-top: 16px;
        font-size: 1.2rem;
    }

    .card--subtitle {
        font-size: 1rem;
    }

    .card--about {
        margin-top: 24px;
        font-weight: 700;
        font-size: 1.2rem;
        color: #969596;
    }

    .card--description {
        margin-top: 12px;
        font-size: 1rem;
        font-family: 'DM Sans Medium';
    }
}
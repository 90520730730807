
@media only screen and (max-width: 375px) {

    .card--communities {
        margin-top: 12px;
    
        .card--community-image {
            height: 40px;
            width: 40px;
            margin-bottom: 4px;
            aspect-ratio: 16 / 9;
            object-fit: contain;
        }
    
        .card--communities-posts-line-success {
            background-color: rgba(0, 197, 49, 0.7);
            height: 2px;
        }
    
        .card--communities-posts-line-error {
            background-color: rgba(197, 0, 0, 0.7);
            height: 2px;
        }
    
        .card--communities-posts {
            padding: 4px 12px;
        }
    }
    
}

@media only screen and (max-width: 768px) {
    .d-card--communities {
        display: none;
    }

    .card--communities {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: fit-content;
    }

    .card--communities-posts {
        display: flex;
        flex-direction: column;
        padding: 0 4px;
        align-items: center;

        .card--communities-posts-line-success {
            background-color: rgba(0, 197, 49, 0.7);
            height: 3px;
            width: 90%;
        }

        .card--communities-posts-line-error {
            background-color: rgba(197, 0, 0, 0.7);
            height: 3px;
            width: 90%;
        }

        .card--community-image {
            height: 48px;
            width: 48px;
            margin-bottom: 8px;
            aspect-ratio: 16 / 9;
            object-fit: contain;
        }
    }

    .position--container {
        margin: 0;
    }
}

@media only screen and (min-width: 769px) {
    .card--communities {
        display: none;
    }

    .d-card--communities {
        display: flex;
        flex-direction: column;
        margin: 24px 0 ;

        h3 {
            color: #969596;
        }
        .d-card--content-communities {
            display: flex;
            flex-direction: row;
        }

        .d-card--communities-posts {
            display: flex;
            border: 1px solid #c7c4c778;
            margin-right: 24px;
            border-radius: 12px;
            padding: 12px 0;
            cursor: pointer;

            img {
                padding: 0 12px;
                height: 84px;
                width: 84px;
                object-fit: contain;
            }
        }
    }

    .opinion--container {
        display: flex;
        flex-direction: column;
        margin: 32px 32px 32px 8px;

        .opinion--title {
            font-size: 1.2em;
            font-family: 'DM Sans Medium';
            color: #f66e1c;
            margin-bottom: 8px;
        }

        .opinion--subtitle {
            color: #969596;
            font-family: 'DM Sans Regular';
            font-weight: bold;
        }
    }
}

.MuiModal-root {
    bottom: 50% !important;
    top: inherit !important;
}

.modal--opinions {
    background-color: #fff;
    display: flex;
    flex-direction: column; 
    border-radius: 12px;
    height: fit-content;
    width: 30%;

    img {
        width: 124px;
        margin: 12px;
        margin-bottom: 0;
    }

    .position--container {
        margin-bottom: 12px;
    }
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 120px;
    align-items: flex-start;
    background-color: rgba(250, 250, 250);
    top: 0;
    position: sticky;
    justify-content: space-around;
    z-index: 10;
    box-shadow: 0px 2px 2px hsl(0, 0%, 91%);
}


@media only screen and (max-width: 768px) {
    .header {
        .container {
            padding: 24px 24px 0 24px;
            display: flex;
            align-items: flex-start;

            svg {
                width: 3rem;
                margin-top: 4px;
            }
        }
        .text--container {
            flex: 1;
            margin-left: 16px;

            h1 {
                font-size: 1.5rem;
            }

            p {
                font-size: .9rem;
            }
        }
    }

}

@media only screen and (max-width: 375px) {
    .header {
        .container {
            padding: 14px;
            display: flex;
            align-items: flex-start;

            svg {
                width: 2.5rem;
                margin-top: 4px;
            }
        }

        .text--container {
            flex: 1;
            margin-left: 8px;

            h1 {
                font-size: 1.2rem;
            }

            p {
                font-size: .8rem;
            }
        }
    }
}

@media only screen and (min-width: 2000px) {
    .header {
        .container {
            width: 30%;
            padding: 24px !important;
            align-items: normal !important;
        }

        svg {
            width: 80px !important;
            margin-top: 0px !important;
            margin-bottom: 18px;
        }

        .text--container {
            margin-left: 30px;

            h1 {
                font-size: 2rem !important;
                text-transform: uppercase;
                font-family: 'DM Sans Medium';
                color: #f66e1c;
                margin-block-end: 0;
            }
    
            p {
                font-family: 'DM Sans Medium';
                font-size: 1rem !important;
                margin-block: 6px;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .header {
        .container {
            width: 70%;
            padding: 24px !important;
            align-items: normal !important;
            display: flex;
        }

        svg {
            margin-top: 0px !important;
            margin-bottom: 18px;
        }

        .text--container {
            margin-left: 30px;
            max-width: 75%;

            h1 {
                font-size: 1.4rem;
                text-transform: uppercase;
                font-family: 'DM Sans Medium';
                color: #f66e1c;
                margin-block-end: 0;
            }
    
            p {
                font-family: 'DM Sans Regular';
                font-size: 1rem !important;
                margin-block: 6px;
            }
        }
    }
}
$page-padding: 24px;
$color-highlight: #f66e1c;
$color-text-secondary: rgba(150, 149, 150, 0.6);

@media only screen and (max-width: 768px) {
  h1 {
    line-height: 0;
    text-transform: uppercase;
    color: $color-highlight;
    font-size: 24px;
  }
  p {
    font-size: 14px;
    margin-block-start: 1.5em;
    font-family: 'DM Sans Medium';
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

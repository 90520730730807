.opacity {
    opacity: .5;
}

.card--button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 12px;

    .selected {
        flex: 1.4 !important;
    }

    .not-selected {
        opacity: .5;
    }

    .selected-enter {
        opacity: 1;
    }

    .selected-enter-active {
        opacity: 1;
        transition: 200ms;
        flex: 1.4 !important;
    }

    .selected-enter-done {
        opacity: 1;
        flex: 1.4 !important;
    }

    .selected-exit {
        opacity: 1;
        flex: 1.4 !important;
    }

    .selected-exit-active {
        flex: 1 !important;
        transition: 200ms;
    }

    .button {
        height: 54px;
        flex: 1;
        border-radius: 6px;
        border: 0;
        transition: 0.5s ease-out;
    }

    .left {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 0px;
    }
    
    .right {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
    
    .card--button-in-favor {
        background-color: rgba(0, 197, 49);
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 0px;
    }

    .card--button-against {
        background-color: rgba(233, 0, 0);
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

    .card--button-text-container {
        display: flex;
        justify-content: space-around;
        align-items: center;

        span {
            width: 40%;
            text-align: start;
            color: #fff;
            font-weight: 700;
        }

        .card--button-icon {
            height: 18px;
            width: 18px;
            color: #fff;
        }
    }
}

@media only screen and (max-width: 768px) {
    .card--button-container {
        margin-top: 8px;
    
        .button {
            height: 48px;
        }
        .card--button-text-container {
            span {
                font-size: .7rem;
                line-height: 1.2;
            }
            .card--button-icon {
                height: 18px;
                width: 18px;
            }
        }
    }
}
